/* roboto-condensed-300normal - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Roboto Condensed Light '),
    local('Roboto Condensed-Light'),
    url(/static/media/roboto-condensed-latin-300.47d23646.woff2) format('woff2'), 
    url(/static/media/roboto-condensed-latin-300.46541d7e.woff) format('woff'); /* Modern Browsers */
}

/* roboto-condensed-300italic - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Roboto Condensed Light italic'),
    local('Roboto Condensed-Lightitalic'),
    url(/static/media/roboto-condensed-latin-300italic.2725306a.woff2) format('woff2'), 
    url(/static/media/roboto-condensed-latin-300italic.2f3c7709.woff) format('woff'); /* Modern Browsers */
}

/* roboto-condensed-400normal - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Roboto Condensed Regular '),
    local('Roboto Condensed-Regular'),
    url(/static/media/roboto-condensed-latin-400.587de8ec.woff2) format('woff2'), 
    url(/static/media/roboto-condensed-latin-400.6da41a0d.woff) format('woff'); /* Modern Browsers */
}

/* roboto-condensed-400italic - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Roboto Condensed Regular italic'),
    local('Roboto Condensed-Regularitalic'),
    url(/static/media/roboto-condensed-latin-400italic.e21bf4e6.woff2) format('woff2'), 
    url(/static/media/roboto-condensed-latin-400italic.3919beb4.woff) format('woff'); /* Modern Browsers */
}

/* roboto-condensed-700normal - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Roboto Condensed Bold '),
    local('Roboto Condensed-Bold'),
    url(/static/media/roboto-condensed-latin-700.c074f8ef.woff2) format('woff2'), 
    url(/static/media/roboto-condensed-latin-700.bf9fec98.woff) format('woff'); /* Modern Browsers */
}

/* roboto-condensed-700italic - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Roboto Condensed Bold italic'),
    local('Roboto Condensed-Bolditalic'),
    url(/static/media/roboto-condensed-latin-700italic.fd4c5ff6.woff2) format('woff2'), 
    url(/static/media/roboto-condensed-latin-700italic.fb176197.woff) format('woff'); /* Modern Browsers */
}


/* poppins-100normal - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src:
    local('Poppins Thin '),
    local('Poppins-Thin'),
    url(/static/media/poppins-latin-100.46a15b4f.woff2) format('woff2'), 
    url(/static/media/poppins-latin-100.ad37abfa.woff) format('woff'); /* Modern Browsers */
}

/* poppins-100italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src:
    local('Poppins Thin italic'),
    local('Poppins-Thinitalic'),
    url(/static/media/poppins-latin-100italic.a1c50095.woff2) format('woff2'), 
    url(/static/media/poppins-latin-100italic.f1dcb825.woff) format('woff'); /* Modern Browsers */
}

/* poppins-200normal - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src:
    local('Poppins Extra Light '),
    local('Poppins-Extra Light'),
    url(/static/media/poppins-latin-200.4e9592cd.woff2) format('woff2'), 
    url(/static/media/poppins-latin-200.7eef082f.woff) format('woff'); /* Modern Browsers */
}

/* poppins-200italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-display: swap;
  font-weight: 200;
  src:
    local('Poppins Extra Light italic'),
    local('Poppins-Extra Lightitalic'),
    url(/static/media/poppins-latin-200italic.de442134.woff2) format('woff2'), 
    url(/static/media/poppins-latin-200italic.db53fe94.woff) format('woff'); /* Modern Browsers */
}

/* poppins-300normal - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Poppins Light '),
    local('Poppins-Light'),
    url(/static/media/poppins-latin-300.e8794816.woff2) format('woff2'), 
    url(/static/media/poppins-latin-300.5c5aa257.woff) format('woff'); /* Modern Browsers */
}

/* poppins-300italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Poppins Light italic'),
    local('Poppins-Lightitalic'),
    url(/static/media/poppins-latin-300italic.263658ab.woff2) format('woff2'), 
    url(/static/media/poppins-latin-300italic.e125f1e2.woff) format('woff'); /* Modern Browsers */
}

/* poppins-400normal - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Poppins Regular '),
    local('Poppins-Regular'),
    url(/static/media/poppins-latin-400.1a280523.woff2) format('woff2'), 
    url(/static/media/poppins-latin-400.46a7d482.woff) format('woff'); /* Modern Browsers */
}

/* poppins-400italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Poppins Regular italic'),
    local('Poppins-Regularitalic'),
    url(/static/media/poppins-latin-400italic.ac1612e2.woff2) format('woff2'), 
    url(/static/media/poppins-latin-400italic.9c9695b0.woff) format('woff'); /* Modern Browsers */
}

/* poppins-500normal - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Poppins Medium '),
    local('Poppins-Medium'),
    url(/static/media/poppins-latin-500.61e2d96d.woff2) format('woff2'), 
    url(/static/media/poppins-latin-500.1372de09.woff) format('woff'); /* Modern Browsers */
}

/* poppins-500italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src:
    local('Poppins Medium italic'),
    local('Poppins-Mediumitalic'),
    url(/static/media/poppins-latin-500italic.2fa39b39.woff2) format('woff2'), 
    url(/static/media/poppins-latin-500italic.335c9c91.woff) format('woff'); /* Modern Browsers */
}

/* poppins-600normal - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Poppins SemiBold '),
    local('Poppins-SemiBold'),
    url(/static/media/poppins-latin-600.aa4405ed.woff2) format('woff2'), 
    url(/static/media/poppins-latin-600.e3bbab8d.woff) format('woff'); /* Modern Browsers */
}

/* poppins-600italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src:
    local('Poppins SemiBold italic'),
    local('Poppins-SemiBolditalic'),
    url(/static/media/poppins-latin-600italic.148405f9.woff2) format('woff2'), 
    url(/static/media/poppins-latin-600italic.4e103675.woff) format('woff'); /* Modern Browsers */
}

/* poppins-700normal - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Poppins Bold '),
    local('Poppins-Bold'),
    url(/static/media/poppins-latin-700.e535f785.woff2) format('woff2'), 
    url(/static/media/poppins-latin-700.bc1e47a3.woff) format('woff'); /* Modern Browsers */
}

/* poppins-700italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Poppins Bold italic'),
    local('Poppins-Bolditalic'),
    url(/static/media/poppins-latin-700italic.5cfb020a.woff2) format('woff2'), 
    url(/static/media/poppins-latin-700italic.89874573.woff) format('woff'); /* Modern Browsers */
}

/* poppins-800normal - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src:
    local('Poppins ExtraBold '),
    local('Poppins-ExtraBold'),
    url(/static/media/poppins-latin-800.b5cd7d83.woff2) format('woff2'), 
    url(/static/media/poppins-latin-800.bde99287.woff) format('woff'); /* Modern Browsers */
}

/* poppins-800italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src:
    local('Poppins ExtraBold italic'),
    local('Poppins-ExtraBolditalic'),
    url(/static/media/poppins-latin-800italic.28a7dac2.woff2) format('woff2'), 
    url(/static/media/poppins-latin-800italic.1d727abb.woff) format('woff'); /* Modern Browsers */
}

/* poppins-900normal - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Poppins Black '),
    local('Poppins-Black'),
    url(/static/media/poppins-latin-900.f030f5cf.woff2) format('woff2'), 
    url(/static/media/poppins-latin-900.fde0f399.woff) format('woff'); /* Modern Browsers */
}

/* poppins-900italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Poppins Black italic'),
    local('Poppins-Blackitalic'),
    url(/static/media/poppins-latin-900italic.f3c803b2.woff2) format('woff2'), 
    url(/static/media/poppins-latin-900italic.e8d920f5.woff) format('woff'); /* Modern Browsers */
}


/* nunito-200normal - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src:
    local('Nunito Extra Light '),
    local('Nunito-Extra Light'),
    url(/static/media/nunito-latin-200.2a4f4b0a.woff2) format('woff2'), 
    url(/static/media/nunito-latin-200.249c20ee.woff) format('woff'); /* Modern Browsers */
}

/* nunito-200italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-display: swap;
  font-weight: 200;
  src:
    local('Nunito Extra Light italic'),
    local('Nunito-Extra Lightitalic'),
    url(/static/media/nunito-latin-200italic.89ba5530.woff2) format('woff2'), 
    url(/static/media/nunito-latin-200italic.67d3d63c.woff) format('woff'); /* Modern Browsers */
}

/* nunito-300normal - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Nunito Light '),
    local('Nunito-Light'),
    url(/static/media/nunito-latin-300.895205e2.woff2) format('woff2'), 
    url(/static/media/nunito-latin-300.cad1324b.woff) format('woff'); /* Modern Browsers */
}

/* nunito-300italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Nunito Light italic'),
    local('Nunito-Lightitalic'),
    url(/static/media/nunito-latin-300italic.f4a613c7.woff2) format('woff2'), 
    url(/static/media/nunito-latin-300italic.1fde3692.woff) format('woff'); /* Modern Browsers */
}

/* nunito-400normal - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Nunito Regular '),
    local('Nunito-Regular'),
    url(/static/media/nunito-latin-400.de6068bf.woff2) format('woff2'), 
    url(/static/media/nunito-latin-400.6386ba6a.woff) format('woff'); /* Modern Browsers */
}

/* nunito-400italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Nunito Regular italic'),
    local('Nunito-Regularitalic'),
    url(/static/media/nunito-latin-400italic.6ca737af.woff2) format('woff2'), 
    url(/static/media/nunito-latin-400italic.ef2e2a0d.woff) format('woff'); /* Modern Browsers */
}

/* nunito-600normal - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Nunito SemiBold '),
    local('Nunito-SemiBold'),
    url(/static/media/nunito-latin-600.b10ecee2.woff2) format('woff2'), 
    url(/static/media/nunito-latin-600.c3de2301.woff) format('woff'); /* Modern Browsers */
}

/* nunito-600italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src:
    local('Nunito SemiBold italic'),
    local('Nunito-SemiBolditalic'),
    url(/static/media/nunito-latin-600italic.97d4f64b.woff2) format('woff2'), 
    url(/static/media/nunito-latin-600italic.4f62b81f.woff) format('woff'); /* Modern Browsers */
}

/* nunito-700normal - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Nunito Bold '),
    local('Nunito-Bold'),
    url(/static/media/nunito-latin-700.91ae827a.woff2) format('woff2'), 
    url(/static/media/nunito-latin-700.623f5ed4.woff) format('woff'); /* Modern Browsers */
}

/* nunito-700italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Nunito Bold italic'),
    local('Nunito-Bolditalic'),
    url(/static/media/nunito-latin-700italic.b94caac4.woff2) format('woff2'), 
    url(/static/media/nunito-latin-700italic.3aabf345.woff) format('woff'); /* Modern Browsers */
}

/* nunito-800normal - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src:
    local('Nunito ExtraBold '),
    local('Nunito-ExtraBold'),
    url(/static/media/nunito-latin-800.36e4c9b4.woff2) format('woff2'), 
    url(/static/media/nunito-latin-800.33c733af.woff) format('woff'); /* Modern Browsers */
}

/* nunito-800italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src:
    local('Nunito ExtraBold italic'),
    local('Nunito-ExtraBolditalic'),
    url(/static/media/nunito-latin-800italic.0f203287.woff2) format('woff2'), 
    url(/static/media/nunito-latin-800italic.672d2afe.woff) format('woff'); /* Modern Browsers */
}

/* nunito-900normal - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Nunito Black '),
    local('Nunito-Black'),
    url(/static/media/nunito-latin-900.b296e05d.woff2) format('woff2'), 
    url(/static/media/nunito-latin-900.cbf4339e.woff) format('woff'); /* Modern Browsers */
}

/* nunito-900italic - latin */
@font-face {
  font-family: 'Nunito';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Nunito Black italic'),
    local('Nunito-Blackitalic'),
    url(/static/media/nunito-latin-900italic.ae87e7b4.woff2) format('woff2'), 
    url(/static/media/nunito-latin-900italic.602859bd.woff) format('woff'); /* Modern Browsers */
}


